import { type MouseEvent, useState, type FC, type ReactNode, type KeyboardEvent } from 'react';

const ExpandableContainer: FC<{
    className?: string;
    children: ReactNode;
    onKeyDown: (e: KeyboardEvent) => void;
    onClick: (e: MouseEvent) => void;
}> = ({ className, children, onKeyDown, onClick }) => (
    <div role="button" tabIndex={0} onKeyDown={onKeyDown} className={className} onClick={onClick}>
        {children}
    </div>
);

export const useExpandableContainer = (
    { initiallyExpanded = false }: { initiallyExpanded: boolean } = { initiallyExpanded: false },
) => {
    const [open, setOpen] = useState(initiallyExpanded);
    const toggleOpen = () => setOpen(v => !v);
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            toggleOpen();
        }
    };

    return { open, toggleOpen, handleKeyDown, ExpandableContainer };
};
