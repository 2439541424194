import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { isEqual } from 'lodash/fp';
import { createContext, memo, useEffect, useMemo, useState, type FC } from 'react';
import { twMerge } from 'Utils/customTwMerge';

import { useExpandableContainer } from './useExpandableContainer';

type CardProps = {
    header: React.ReactNode;
    body: React.ReactNode;
    cardClassName?: string;
    buttonClassName?: string;
    initiallyExpanded?: boolean;
};

const classes = {
    card: clsx([
        'leading-none',
        'w-full',
        'rounded',
        'bg-white',
        'shadow-[0px_0px_10px_0px_rgba(0,0,0,.1)]',
        'mb-2',
        'last:mb-0',
    ]),
    titleBox: clsx(['flex', 'min-h-[30px]', 'items-center', 'w-full']),
    container: clsx([
        'flex',
        'justify-between',
        'items-center',
        'min-h-14',
        'ms-[15px]',
        'me-[15px]',
        'sm:me-[30px]',
        'bg-transparent',
        'hover:cursor-pointer',
    ]),
    checkIcon: clsx(['ms-[5px]', 'h-[14px]', 'w-[14px]', 'flex-shrink-0', 'inline-block', 'align-[-.125em]']),
    chevronClasses: clsx(['w-[30px]', 'sm:w-auto', 'hover:text-blue', 'transition-transform', 'duration-[250ms]']),
    bodyWrapper: clsx(['transition-opacity', 'duration-[300ms]']),
};

export const ExpandableCardListContext = createContext<{ open: boolean }>({ open: false });

const ExpandableCardListComponent: FC<CardProps> = ({
    header,
    body,
    cardClassName,
    buttonClassName,
    initiallyExpanded = false,
}) => {
    const { open, ExpandableContainer, toggleOpen, handleKeyDown } = useExpandableContainer({ initiallyExpanded });
    const contextValue = useMemo(() => ({ open }), [open]);

    const [expanded, setExpanded] = useState(open);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (open && !expanded) {
            setExpanded(true);
        } else if (!open && expanded) {
            timeoutId = setTimeout(() => setExpanded(false), 200);
        }

        return () => clearTimeout(timeoutId);
    }, [open, expanded]);

    return (
        <ExpandableCardListContext.Provider value={contextValue}>
            <div className={twMerge(classes.card, cardClassName)}>
                <ExpandableContainer
                    className={twMerge(classes.container, buttonClassName)}
                    onKeyDown={handleKeyDown}
                    onClick={toggleOpen}
                >
                    <div className={classes.titleBox}>{header}</div>
                    <FontAwesomeIcon
                        className={clsx(classes.chevronClasses, { 'rotate-180': open }, { 'rotate-0': !open })}
                        icon={faChevronDown}
                        color={open ? '#4C79FF' : '#A9A197'}
                    />
                </ExpandableContainer>
                <div
                    className={clsx(
                        classes.bodyWrapper,
                        { 'opacity-0 ease-out': !open },
                        { 'opacity-100 ease-in': open },
                    )}
                >
                    {expanded && body}
                </div>
            </div>
        </ExpandableCardListContext.Provider>
    );
};

export const ExpandableCardList = memo(ExpandableCardListComponent, isEqual) as typeof ExpandableCardListComponent;
